import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import Modal from '../Modal.jsx';
import HeaderContainer from '../HeaderContainer.jsx';
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  TableButton,
} from '../../components/Custom-UI/Tables/Elements.jsx';
import EvaluationForm from '../../components/Evaluations/EvaluationForm.jsx';
import Switch from '../Form/Switch.jsx';
import { useGetLearnunits } from '../../models/learnunits.js';
import { useGetCourseEvaluations, deleteEvaluation, patchEvaluation } from '../../models/evaluations.js';

const evaluationsName = {
  Practice: 'Práctica',
  Tournament: 'Torneo',
};

const evalTypeColor = {
  Practice: 'bg-blue-200 px-2 py-1 rounded-md',
  Tournament: 'bg-green-200 px-2 py-1 rounded-md',
};

function EvaluationsTable({ course, courseLearnunitName }) {
  const { id, unit_id } = useParams();
  const navigate = useNavigate();

  const [learningUnit, setLearningUnit] = useState({ name: '' });
  const [evaluations, setEvaluations] = useState([]);
  const [evaluationsData, evaluationsDataLoading] = useGetCourseEvaluations(id, unit_id);
  const [learnunitsData, learnunitsDataLoading] = useGetLearnunits();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(null);
  const [evaluationToEdit, setEvaluationToEdit] = useState({});
  const [method, setMethod] = useState('POST');

  const itemsBreadcrumb = {
    items: [
      { name: 'Cursos', href: '/courses' },
      { name: 'Unidades de aprendizaje', href: `/courses/${course?.id}/learning_units` },
      {
        name: `Evaluaciones del curso ${course?.name} de la unidad ${courseLearnunitName}`,
        href: '',
      },
    ],
    maxItems: 3,
  };

  const handleDelete = async evaluation_id => {
    const confirmation = window.confirm('¿Estás seguro de eliminar esta evaluación?');
    if (confirmation) {
      const response = await deleteEvaluation(parseInt(evaluation_id));
      if (response.status === 200) {
        alert('Evaluación eliminada');
        window.location.reload();
      } else {
        alert('Error al eliminar la evaluación');
      }
    }
  };

  const handleChangePublish = async (e, current_evaluation) => {
    const { checked } = e.target;

    let notify = false;
    
    if (checked) {
      const notifyStudents = window.confirm('¿Desea notificar a los estudiantes sobre la publicación de la evaluación?');
      notify = notifyStudents ? true : false;
    }

    const updatedEvaluation = { 
      ...current_evaluation,
      publish: checked,
      notify: notify,
    };

    setEvaluations(prevEvaluations =>
      prevEvaluations.map(evaluation =>
        evaluation.id === current_evaluation.id 
        ? { ...evaluation, publish: checked } 
        : evaluation
      )
    );

    try {
      const response = await patchEvaluation(current_evaluation.id, updatedEvaluation);
      if (response.status !== 200) {
        setEvaluations(prevEvaluations =>
          prevEvaluations.map(evaluation =>
            evaluation.id === current_evaluation.id
            ? { ...evaluation, publish: !checked }
            : evaluation
          )
        );
        alert('Error al actualizar la evaluación');
      }
    } catch (error) {
      alert('Error al actualizar la evaluación');
      setEvaluations(prevEvaluations =>
        prevEvaluations.map(evaluation =>
          evaluation.id === current_evaluation.id
          ? { ...evaluation, publish: !checked }
          : evaluation
        )
      );
    }

  };

  const getFormattedDate = dateAndTime => {
    const [date, time] = dateAndTime.split('T');
    return `${date} ${time.split('.')[0]}`;
  };

  useEffect(() => {
    if (learnunitsData && !learnunitsDataLoading) {
      for (let i = 0; i < learnunitsData.data.length; i++) {
        if (parseInt(learnunitsData.data[i].id) === parseInt(unit_id)) {
          setLearningUnit(learnunitsData.data[i]);
          break;
        }
      }
    }
  }, [learnunitsData, learnunitsDataLoading, unit_id]);

  useEffect(() => {
    if (evaluationsData && !evaluationsDataLoading) {
      setEvaluations(evaluationsData);
    }
  }, [evaluationsData, evaluationsDataLoading]);

  return (
    <div className='antialiased font-sans w-full'>
      <Modal open={open} setOpen={setOpen}>
        <EvaluationForm
          type={type}
          course_id={course.id}
          unit_id={learningUnit.id}
          setOpen={setOpen}
          eval_components={evaluationToEdit}
          method={method}
        />
      </Modal>
      <div className='divide-y divide-primary'>
        <HeaderContainer
          breadcrumb={itemsBreadcrumb}
          actions={[
            {
              title: 'Añadir Práctica',
              icon: <AddIcon />,
              handleClick: () => {
                setEvaluationToEdit({});
                setMethod('POST');
                setType('Practice');
                setOpen(true);
              },
            },
            {
              title: 'Añadir Torneo',
              icon: <AddIcon />,
              handleClick: () => {
                setEvaluationToEdit({});
                setMethod('POST');
                setType('Tournament');
                setOpen(true);
              },
            },
            {},
          ]}
        />
        <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>Tipo</TableHeader>
                <TableHeader>Nombre</TableHeader>
                <TableHeader>Descripción</TableHeader>
                <TableHeader>Mesas por evaluación</TableHeader>
                <TableHeader>Preguntas por mesa</TableHeader>
                <TableHeader>Fechas</TableHeader>
                <TableHeader>MediBot</TableHeader>
                <TableHeader>Preguntas</TableHeader>
                <TableHeader>Publicar</TableHeader>
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {evaluations.map(evaluation => (
                <TableRow key={evaluation.id}>
                  <TableCell>
                    <span className={evalTypeColor[evaluation.eval_type]}>
                      <b>{evaluationsName[evaluation.eval_type]}</b>
                    </span>
                  </TableCell>
                  <TableCell>{evaluation.name}</TableCell>
                  <TableCell>{evaluation.description}</TableCell>
                  <TableCell>{evaluation.tables_per_evaluation}</TableCell>
                  <TableCell>{evaluation.questions_per_table}</TableCell>
                  <TableCell>
                    {evaluation.eval_type === 'Tournament' ? (
                      <div className='flex flex-col gap-2'>
                        <div>
                          <b>Inicio:</b>
                          <p>{getFormattedDate(evaluation.starting_date)}</p>
                        </div>
                        <div>
                          <b>Término:</b>
                          <p>{getFormattedDate(evaluation.ending_date)}</p>
                        </div>
                      </div>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <div className='flex flex-col gap-2'>
                      <p>
                        <b>Consultas ganadas: </b>
                        {evaluation.API_calls_won}
                      </p>
                      <p>
                        <b>Días en que expiran: </b>
                        {evaluation.API_calls_expire}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell>
                    <TableButton
                      variant={'text'}
                      onClick={() =>
                        navigate(`/courses/${id}/${unit_id}/evaluations/${evaluation.id}/question`)
                      }>
                      <div className='inline-flex items-center my-2'>
                        <div className='mr-1'>
                          <HelpOutlineOutlinedIcon />
                        </div>
                        Preguntas
                      </div>
                    </TableButton>
                  </TableCell>
                  <TableCell>
                    <div className='flex -ml-4'>
                      <Switch
                        name='publish'
                        value={evaluation.publish}
                        onChange={(e) => handleChangePublish(e, evaluation)}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className='flex w-full justify-between gap-2 items-center'>
                      <TableButton
                        variant={'edit'}
                        onClick={() => {
                          setEvaluationToEdit(evaluation);
                          setType(evaluation.eval_type);
                          setMethod('PATCH');
                          setOpen(true);
                        }}>
                        <EditOutlinedIcon />
                      </TableButton>
                      <TableButton variant={'delete'} onClick={() => handleDelete(evaluation.id)}>
                        <DeleteOutlinedIcon color='red' />
                      </TableButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default EvaluationsTable;
