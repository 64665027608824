import { useAsync } from 'react-use';
import * as Sentry from '@sentry/react';

import api from '../api/axios';
import authorizationHeader from '../api/token';

// GET all images
export function useGetImages(page, limit, typeFilter, learnunitFilter) {
  const { value, loading, error } = useAsync(
    () => getImages(page, limit, typeFilter, learnunitFilter),
    [page, limit, typeFilter, learnunitFilter]
  );
  return [value, loading, error];
}

export const getImages = async (page, limit, typeFilter = '', learnunitFilter = '') => {
  const token = await authorizationHeader();
  const images = await api
    //should be /images/
    .get(
      `/images?page=${page}&limit=${limit}&typeFilter=${typeFilter}&learnunitFilter=${learnunitFilter}`,
      {
        headers: token,
      }
    )
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return images.data;
};

export const getAllImages = async () => {
  try {
    const token = await authorizationHeader();
    const totalImages = await api.get(`/images/`, {
      headers: token,
    });

    const images = await api
      //should be /images/
      .get(`/images?page=${1}&limit=${totalImages.data.pagination.totalImages}`, {
        headers: token,
      });
    return images.data.images;
  } catch (err) {
    Sentry.captureException(err);
    if (err?.response?.data) {
      Sentry.captureMessage(err.response.data);
    }
    return [];
  }

  // const data = await getImages(1, 12);
  // const pages = data?.pagination.pages;
  // let images = [];
  // for (let i = 1; i < pages + 1; i++) {
  //   const data = await getImages(i, 12);
  //   for (let j = 0; j < 12; j++) {
  //     if (data?.images[j]) {
  //       images.push(data?.images[j]);
  //     }
  //   }
  // }
  // return images;
};

// GET image by id
export function useGetImage(imageId) {
  const { value, loading, error } = useAsync(() => getImage(imageId), [imageId]);
  return [value, loading, error];
}

export const getImage = async imageId => {
  const token = await authorizationHeader();
  const images = await api
    .get(`/images/${imageId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return images.data;
};

// POST image
export const postImage = async image => {
  const token = await authorizationHeader();
  const images = await api
    .post(`/images/new`, image, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return images;
};

export const postImages = async images => {
  const token = await authorizationHeader();
  const res = await api
    .post(`/images/new_bulk`, images, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return res;
};

export const postImageUpload = async image => {
  const token = await authorizationHeader();
  var formData = new FormData();
  formData.append('image', image);
  const result = await api
    .post('/file-uploader/image', formData, { headers: token })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return result.data;
};

export const postImagesUpload = async images => {
  const token = await authorizationHeader();
  var formData = new FormData();
  for (let i = 0; i < images.length; i++) {
    formData.append('image', images[i]);
  }
  const result = await api
    .post('/file-uploader/images', formData, { headers: token })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return result;
};

export const getImageUpload = async image => {
  const token = await authorizationHeader();
  const result = await api
    .get(`/file-uploader/images/${image}`, { headers: token })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return result.data;
};

// DELETE image
export const deleteImage = async imageId => {
  const token = await authorizationHeader();
  const images = await api
    .delete(`/images/delete/${imageId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return images;
};

// PATCH image

export const patchImage = async (id, image) => {
  const token = await authorizationHeader();
  const images = await api
    .patch(`/images/update/${id}`, image, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return images;
};
