import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Avatar from '@mui/material/Avatar';
import { grey } from '@mui/material/colors';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';

import ImageThumbnail from './ImageThumbnail.jsx';
import VideoThumbnail from './VideoThumbnail.jsx';
import { cn } from '../../../utils/cn';
import { getCurrentUser } from '../../../models/user.js';
import IconBot from '../../../assets/images/ICON_bot.svg';

const modelToOption = {
  'GPT-3.5': 'Opción 0',
  'GPT-4': 'Opción 1',
  LLAMA3: 'Opción 2',
  PINECONE: 'Opción 3',
};

function UserMessages({ message }) {
  return (
    <>
      <span className='p-3 rounded-md rounded-tr-none max-w-xs bg-primary text-white text-left'>
        {message.content}
      </span>
      <div className='flex justify-end'>
        <Avatar sx={{ bgcolor: '#004591' }} />
      </div>
    </>
  );
}

function AssistantMessages({ message, role, models }) {
  const [selectedModel, setSelectedModel] = useState(
    message.isSelected ? modelToOption[message.isSelected] : ''
  );
  const [userSelectedModel, setUserSelectedModel] = useState(
    message.isSelected ? message.isSelected : ''
  );

  useEffect(() => {
    if (role === 'ADMIN') {
      setSelectedModel(message.isSelected ? message.isSelected : '');
    }
  }, [role]);

  const DEFAULT_ANSWER = 'No existe contenido para este modelo';

  const handleSelect = model => {
    setSelectedModel(model);
  };

  useEffect(() => {
    if (message.isSelected === undefined) {
      if (message.content === message.contentGPT4) {
        setUserSelectedModel(role === 'ADMIN' ? 'GPT-4' : 'Opción 1');
        setSelectedModel(role === 'ADMIN' ? 'GPT-4' : 'Opción 1');
      } else if (message.content === message.contentLlama3) {
        setUserSelectedModel(role === 'ADMIN' ? 'LLAMA3' : 'Opción 2');
      } else if (message.content === message.contentPineCone) {
        setUserSelectedModel(role === 'ADMIN' ? 'PINECONE' : 'Opción 3');
      } else if (message.content === message.contentGPT3) {
        setUserSelectedModel(role === 'ADMIN' ? 'GPT-3.5' : 'Opción 0');
        setSelectedModel(role === 'ADMIN' ? 'GPT-3.5' : 'Opción 0');
      } else {
        setUserSelectedModel(role === 'ADMIN' ? 'Opción única: GPT-4' : 'Opción única 1');
      }
    }
  }, []);

  let content;
  let data;
  let like;
  switch (selectedModel) {
    case 'GPT-3.5' || 'Opción 0':
      content = message.contentGPT3 ? message.contentGPT3 : DEFAULT_ANSWER;
      like = message.likeGPT3;
      break;
    case 'GPT-4' || 'Opción 1':
      content = message.contentGPT4 ? message.contentGPT4 : DEFAULT_ANSWER;
      data = message.jsonDataGPT4;
      like = message.likeGPT4;
      break;
    case 'LLAMA3' || 'Opción 2':
      content = message.contentLlama3 ? message.contentLlama3 : DEFAULT_ANSWER;
      data = message.jsonDataLlama3;
      like = message.likeLlama3;
      break;
    case 'PINECONE' || 'Opción 3':
      content = message.contentPineCone ? message.contentPineCone : DEFAULT_ANSWER;
      data = message.jsonDataPineCone;
      like = message.likePineCone;
      break;
    default:
      content = message.content;
      data = message.jsonData;
  }

  let userSelected = userSelectedModel;
  if (message.isSelected && role === 'TEACHER') {
    userSelected = modelToOption[message.isSelected];
  } else if (message.isSelected && role === 'ADMIN') {
    userSelected = message.isSelected;
  }

  return (
    <>
      <div className='flex justify-center'>
        <Avatar sx={{ bgcolor: grey[300] }}>
          <img src={IconBot} alt='Bot Icon' style={{ width: '70%', height: '70%' }} />
        </Avatar>
      </div>
      <div className='flex flex-col items-start text-left'>
        <div className='flex gap-2 mb-2'>
          {userSelectedModel !== 'Opción única: GPT-4' &&
            userSelectedModel !== 'Opción única: 1' &&
            userSelectedModel !== '' && (
              <>
                {models.map((model, index) => (
                  <button
                    key={index}
                    className={`px-4 py-2 rounded-full text-xs font-semibold text-white hover:text-black hover:bg-primary/50 ${
                      selectedModel === model ? 'bg-primary' : 'bg-secondary'
                    }`}
                    onClick={() => handleSelect(model)}>
                    {model}
                  </button>
                ))}
              </>
            )}
        </div>
        <div className='bg-gray-200 p-3 rounded-md rounded-tl-none max-w-xs'>
          <div className='mb-2'>
            <span className='text-gray-500 block'>
              El usuario seleccionó: <b>{userSelected}</b>
            </span>
            <span className='text-gray-500 block'>
              Estas viendo: <b>{selectedModel}</b>
            </span>
          </div>
          <span className='text-black block'>
            <ReactMarkdown>{content}</ReactMarkdown>
          </span>
          {data?.videos?.length > 0 && (
            <>
              {data?.videos?.map(video => (
                <VideoThumbnail
                  video_id={video.id}
                  video_name={video.title}
                  timestamp={video.start}
                />
              ))}
            </>
          )}
          {data?.images?.length > 0 && (
            <>
              {data?.images.map(image => (
                <ImageThumbnail
                  key={image.id}
                  image_id={image.id}
                  show_name={true}
                  image_name={image.name}
                />
              ))}
            </>
          )}

          <div className='flex flex-direction:row justify-between mt-2'>
            <div className='flex space-x-4'>
              {like === 1 ? (
                <ThumbUpIcon fontSize='small' color='primary' />
              ) : (
                <ThumbUpOutlinedIcon fontSize='small' color='primary' />
              )}
              {like === -1 ? (
                <ThumbDownIcon fontSize='small' color='primary' />
              ) : (
                <ThumbDownOutlinedIcon fontSize='small' color='primary' />
              )}
            </div>
            <span className='text-gray-500 block'>~ MediBot</span>
          </div>
        </div>
      </div>
    </>
  );
}

export function MessagesList({ messages }) {
  const [models, setModels] = useState(['Opción 0', 'Opción 1', 'Opción 2', 'Opción 3']);
  const [role, setRole] = useState('TEACHER');

  useEffect(() => {
    getCurrentUser()
      .then(user => {
        if (user.role === 'ADMIN') {
          setModels(['GPT-3.5', 'GPT-4', 'LLAMA3', 'PINECONE']);
          setRole('ADMIN');
        }
      })
      .catch();
  }, []);

  return (
    <div className='w-full overflow-y-auto overflow-x-hidden h-full flex flex-col'>
      <ol className='w-full overflow-y-auto overflow-x-hidden h-full flex flex-col'>
        {messages?.map((message, index) => (
          <React.Fragment key={index}>
            {message.role !== 'system' && (
              <li
                key={message.id}
                className={cn(
                  'flex flex-col gap-2 p-4 whitespace-pre-wrap',
                  message.role === 'user' ? 'items-end' : 'items-start'
                )}>
                <div className='flex gap-3'>
                  {message.role === 'user' && <UserMessages message={message} />}
                  {message.role === 'assistant' && (
                    <AssistantMessages message={message} role={role} models={models} />
                  )}
                </div>
              </li>
            )}
          </React.Fragment>
        ))}
      </ol>
    </div>
  );
}
