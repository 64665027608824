import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import Modal from '../Modal.jsx';
import QuestionForm from '../../components/Questions/QuestionForm.jsx';
import HeaderContainer from '../HeaderContainer.jsx';
import Loading from '../Loading.jsx';
import Pagination from '../../components/Custom-UI/Tables/Pagination.jsx';
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  TableButton,
} from '../../components/Custom-UI/Tables/Elements.jsx';
import Checkbox from '../Form/Checkbox.jsx';
import ImageComponent from '../ImageComponent.jsx';
import { postQuestionToEvaluation } from '../../models/questions.js';

const correctAnswerVF = { 0: 'F', 1: 'V' };

const LearnunitQuestionsTable = ({ questions }) => {
  const { eval_id } = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    currentPage: page,
    itemsPerPage: itemsPerPage,
    totalPages: Math.ceil(questions.length / itemsPerPage),
  });
  const [loading, setLoading] = useState(false);
  const [showImages, setShowImages] = useState(true);
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const [open, setOpen] = useState(false);
  const [checkedQuestions, setCheckedQuestions] = useState({});

  useEffect(() => {
    setPagination({
      currentPage: page,
      itemsPerPage: itemsPerPage,
      totalPages: Math.ceil(questions.length / itemsPerPage),
    });
    setLoading(false);
  }, [questions, itemsPerPage, page]);

  const postQuestionsToEvaluation = async () => {
    setLoading(true);
    const responses = await Promise.all(
      Object.keys(checkedQuestions).map(question_id => {
        if (checkedQuestions[question_id]) {
          return postQuestionToEvaluation({
            eval_id,
            question_id,
            table: null,
            order: null,
          });
        }
        return null;
      })
    );
    setLoading(false);
    const response = responses.filter(response => {
      if (response === null) {
        return false;
      } else if (!response) {
        return true;
      } else if (response.status !== 200 && response.status !== 201) {
        return true;
      }
      return false;
    });
    if (response.length === 0) {
      alert('Pregunta/s agregada/s a la evaluación corrrectamente.');
      navigate(-1);
    } else {
      alert('Error al añadir la/s pregunta/s a la evaluación. Intente de nuevo.');
      window.location.reload();
    }
  };

  const handleSelection = question_id => {
    setCheckedQuestions(prevState => ({
      ...prevState,
      [question_id]: !prevState[question_id],
    }));
  };

  useEffect(() => {
    const tempCheckedQuestions = {};
    questions.forEach(question => {
      tempCheckedQuestions[question.id] = false;
    });
    setCheckedQuestions(tempCheckedQuestions);
  }, [questions]);

  return (
    <div>
      <Modal open={open} setOpen={setOpen}>
        <QuestionForm setOpen={setOpen} method='PATCH' question={questionToEdit} />
      </Modal>
      <HeaderContainer
        title='Preguntas que NO están en la evaluación'
        actions={[
          {
            title: 'Añadir preguntas seleccionadas',
            handleClick: postQuestionsToEvaluation,
            icon: <AddIcon />,
          },
        ]}
      />
      {loading ? <Loading /> : null}
      <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
        <Checkbox label='Ver imágenes' defaultChecked onChange={() => setShowImages(!showImages)} />
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>Añadir pregunta</TableHeader>
              {showImages ? <TableHeader>Imagen</TableHeader> : null}
              <TableHeader>Pregunta</TableHeader>
              <TableHeader>Respuesta correcta</TableHeader>
              <TableHeader>Tipo</TableHeader>
              <TableHeader>Tiempo (seg.)</TableHeader>
              <TableHeader />
            </TableRow>
          </TableHead>
          <TableBody>
            {questions
              .slice(itemsPerPage * (page - 1), itemsPerPage * page)
              .map((question, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={
                        checkedQuestions[question.id] ? checkedQuestions[question.id] : false
                      }
                      onChange={() => handleSelection(question.id)}
                    />
                  </TableCell>
                  {showImages ? (
                    <TableCell>
                      {question.image_id ? (
                        <ImageComponent image_id={question.image_id} />
                      ) : (
                        'Sin imagen asociada'
                      )}
                    </TableCell>
                  ) : null}
                  <TableCell>{question.content}</TableCell>
                  <TableCell>
                    {question.question_type === 'ALTERNATIVAS'
                      ? question.possible_answers[question.correct_answer]
                      : question.question_type === 'V/F'
                      ? correctAnswerVF[question.correct_answer]
                      : question.possible_answers.join(' | ')}
                  </TableCell>
                  <TableCell>{question.question_type}</TableCell>
                  <TableCell>{question.time_to_answer}</TableCell>
                  <TableCell>
                    <TableButton
                      variant={'edit'}
                      onClick={() => {
                        setQuestionToEdit(question);
                        setOpen(true);
                      }}>
                      <EditOutlinedIcon />
                    </TableButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Pagination
          pagination={pagination}
          setPage={setPage}
          setLoading={setLoading}
          setItemPerPage={setItemsPerPage}
        />
      </div>
    </div>
  );
};

export default LearnunitQuestionsTable;
