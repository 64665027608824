import React from 'react';

import BoxContainer from '../../style-components/BoxContainer.jsx';
import ChatTable from '../../style-components/Tables/ChatTable.jsx';
import Loading from '../../style-components/Loading.jsx';
import { useGetChats } from '../../models/chat.js';

const ChatList = () => {
  const [chats, loadingChats] = useGetChats();

  return (
    <BoxContainer>
      {loadingChats ? (
        <Loading />
      ) : (
        <ChatTable list_chats={chats?.chats} loadingChats={loadingChats} />
      )}
    </BoxContainer>
  );
};

export default ChatList;
