import React, { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import Modal from '../Modal.jsx';
import UserForm from '../../components/Users/UserForm.jsx';
import Select from '../Form/Select.jsx';
import Loading from '../Loading.jsx';
import { SortData } from '../../components/Custom-UI/Tables/Filters.jsx';
import HeaderContainer from '../HeaderContainer.jsx';
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  TableButton,
} from '../../components/Custom-UI/Tables/Elements.jsx';
import Pagination from '../../components/Custom-UI/Tables/Pagination.jsx';
import { useGetUsersWithPagination, deleteUser } from '../../models/user.js';
import { useGetUniversities } from '../../models/universities.js';
import NotificationForm from '../../components/Notification/NotificationForm.jsx';

const roleName = {
  STUDENT: 'Estudiante',
  TEACHER: 'Profesor',
  ADMIN: 'Administrador',
};

const roleColor = {
  STUDENT: 'bg-blue-200 px-2 py-1 rounded-md',
  TEACHER: 'bg-green-200 px-2 py-1 rounded-md',
  ADMIN: 'bg-orange-200 px-2 py-1 rounded-md',
};

const options = [
  { value: '', label: <em>Todos</em> },
  { value: 'STUDENT', label: 'Estudiante' },
  { value: 'TEACHER', label: 'Profesor' },
  { value: 'ADMIN', label: 'Administrador' },
];

function UsersTable() {
  const [roleFilter, setRoleFilter] = useState('');
  const [universityFilter, setUniversityFilter] = useState('');
  const [universitiesData, loadingUniversities] = useGetUniversities();
  const [universities, setUniversities] = useState({});
  const [open, setOpen] = useState(false);
  const [openModalNotification, setOpenModalNotification] = useState(false);
  const [method, setMethod] = useState('POST');
  const [userToEdit, setUserToEdit] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [data, dataLoading] = useGetUsersWithPagination(
    page,
    itemsPerPage,
    roleFilter,
    universityFilter
  );
  const [pagination, setPagination] = useState({
    currentPage: page,
    itemsPerPage: itemsPerPage,
    totalPages: users.length,
  });
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  function sortDataByPath(path, direction) {
    const sortedData = SortData(users, path, direction);
    setUsers(sortedData);
  }

  function handleSearch(option) {
    setSearchValue(option.target.value);
  }

  useEffect(() => {
    if (data && !dataLoading) {
      setUsers(data.users);
      setPagination({
        currentPage: data.pagination.currentPage,
        itemsPerPage: itemsPerPage,
        totalPages: data.pagination.totalPages,
      });
      setLoading(false);
    }
  }, [data, dataLoading]);

  useEffect(() => {
    if (universitiesData && !loadingUniversities) {
      const universitiesDict = {};
      universitiesData.forEach(university => {
        universitiesDict[university.id] = university.name;
      });
      setUniversities(universitiesDict);
    }
  }, [universitiesData, loadingUniversities]);

  function handleRole(option) {
    setRoleFilter(option.target.value);
    setPage(1);
    setLoading(true);
  }

  function handleUniversity(option) {
    if (option.target.value === '') {
      setUniversityFilter('');
    } else {
      setUniversityFilter(parseInt(option.target.value));
    }
    setPage(1);
    setLoading(true);
  }

  const handleDelete = async userId => {
    // ver tema de cognito
    const confirmation = window.confirm('¿Estás seguro que quieres eliminar este usuario?');
    if (confirmation) {
      const response = await deleteUser(userId);
      if (response.status === 200) {
        alert('Usuario eliminado con éxito.');
        window.location.reload();
      } else {
        alert('Error al eliminar el usuario.');
      }
    }
  };

  return (
    <div className='antialiased font-sans w-full'>
      <Modal open={open} setOpen={setOpen}>
        <UserForm setOpen={setOpen} method={method} user={userToEdit} />
      </Modal>
      <Modal open={openModalNotification} setOpen={setOpenModalNotification}>
        <NotificationForm setOpen={setOpenModalNotification} users={users} />
      </Modal>
      <div className='divide-y divide-primary'>
        <HeaderContainer
          title={'Usuarios'}
          search={{ handleSearch: handleSearch }}
          actions={[
            {
              title: 'Añadir usuario',
              icon: <AddIcon />,
              handleClick: () => {
                setUserToEdit({});
                setMethod('POST');
                setOpen(true);
              },
            },
            {
              title: 'Notificación',
              icon: <AddIcon />,
              handleClick: () => {
                setOpenModalNotification(true);
              },
            },
          ]}
        />
        <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
          <div className='flex gap-4 mb-2'>
            <Select
              name='role'
              label='Rol'
              value={roleFilter}
              onChange={handleRole}
              options={options}
              sx={{ minWidth: '160px' }}
            />
            <Select
              name='university'
              label='Universidad'
              value={universityFilter}
              onChange={handleUniversity}
              options={[{ value: '', label: <em>Todas</em> }].concat(
                Object.keys(universities)?.map(university_id => ({
                  value: university_id,
                  label: universities[university_id],
                }))
              )}
              sx={{ minWidth: '140px' }}
            />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader sortable onSort={direction => sortDataByPath('email', direction)}>
                  Correo
                </TableHeader>
                <TableHeader>Nombre/s</TableHeader>
                <TableHeader sortable onSort={direction => sortDataByPath('lastnames', direction)}>
                  Apellido/s
                </TableHeader>
                <TableHeader>Rol</TableHeader>
                <TableHeader>Universidad</TableHeader>
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {dataLoading || loading ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Loading />
                  </TableCell>
                </TableRow>
              ) : (
                users.map(user =>
                  user.email.toLowerCase().includes(searchValue) ? (
                    <TableRow key={user.id}>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.firstnames}</TableCell>
                      <TableCell>{user.lastnames}</TableCell>
                      <TableCell>
                        <span className={roleColor[user.role]}>
                          <b>{roleName[user.role]}</b>
                        </span>
                      </TableCell>
                      <TableCell>{universities[user.university_id]}</TableCell>
                      <TableCell>
                        <div className='flex w-full justify-between gap-2 items-center'>
                          <TableButton
                            variant={'edit'}
                            onClick={() => {
                              setUserToEdit(user);
                              setMethod('PATCH');
                              setOpen(true);
                            }}>
                            <EditOutlinedIcon />
                          </TableButton>
                          <TableButton variant={'delete'} onClick={() => handleDelete(user.id)}>
                            <DeleteOutlinedIcon color='red' />
                          </TableButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : null
                )
              )}
            </TableBody>
          </Table>
          <Pagination
            pagination={pagination}
            setPage={setPage}
            setLoading={setLoading}
            setItemPerPage={setItemsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default UsersTable;
