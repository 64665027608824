import { useAsync } from 'react-use';
import * as Sentry from '@sentry/react';

import api from '../api/axios';
import authorizationHeader from '../api/token';

// GET all questions
export function useGetQuestions(page, limit, learnunitFilter) {
  const { value, loading, error } = useAsync(
    () => getQuestions(page, limit, learnunitFilter),
    [page, limit, learnunitFilter]
  );
  return [value, loading, error];
}

export const getQuestions = async (page, limit, learnunitFilter = '') => {
  const token = await authorizationHeader();
  const questions = await api
    .get(`/questions?page=${page}&limit=${limit}&learnunitFilter=${learnunitFilter}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return questions.data;
};

// GET question by id
export function useGetQuestion(questionId) {
  const { value, loading, error } = useAsync(() => getQuestion(questionId), [questionId]);
  return [value, loading, error];
}

const getQuestion = async questionId => {
  const token = await authorizationHeader();
  const question = await api
    .get(`/questions/${questionId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return question.data;
};

// GET questions by evaluation id
export function useGetQuestionByEvaluation(evaluationId) {
  const { value, loading, error } = useAsync(
    () => getQuestionByEvaluation(evaluationId),
    [evaluationId]
  );
  return [value, loading, error];
}

export const getQuestionByEvaluation = async evaluationId => {
  const token = await authorizationHeader();
  const question = await api
    .get(`/evaluations/${evaluationId}/questions`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return question.data;
};

// get questions by unit id
export function useGetQuestionByUnit(unitId) {
  const { value, loading, error } = useAsync(() => getQuestionByUnit(unitId), [unitId]);
  return [value, loading, error];
}

const getQuestionByUnit = async unitId => {
  const token = await authorizationHeader();
  const question = await api
    .get(`/questions/learnunits/${unitId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return question.data;
};

// POST question
export const postQuestion = async question => {
  const token = await authorizationHeader();
  const response = await api
    .post(`/questions/new`, question, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// get question evaluation
export function useGetQuestionEvaluation(question_id, eval_id) {
  const { value, loading, error } = useAsync(
    () => getQuestionEvaluation(question_id, eval_id),
    [question_id, eval_id]
  );
  return [value, loading, error];
}

// GET question evaluation
export const getQuestionEvaluation = async (question_id, eval_id) => {
  if (!eval_id || !question_id) return null;
  const token = await authorizationHeader();
  const response = await api
    .get(`/questions/get_question_eval/${question_id}/${eval_id}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response.data;
};

// POST question to evaluation
export const postQuestionToEvaluation = async data => {
  const token = await authorizationHeader();
  const response = await api
    .post(`/questions/add_question_to_eval`, data, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// PATCH question evaluation
export const patchQuestionEvaluation = async data => {
  const token = await authorizationHeader();
  const response = await api
    .patch(`/questions/patch_question_eval`, data, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// POST bulk questions
export const postBulkQuestions = async questions => {
  const token = await authorizationHeader();
  const response = await api.post(`/questions/new_bulk`, questions, {
    headers: token,
  });
  return response;
};

// POST bulk questions with evaluation id
export const postBulkQuestionsWithEvaluation = async (questions, eval_id) => {
  const token = await authorizationHeader();
  const response = await api.post(`/questions/evaluation/${eval_id}/new_bulk`, questions, {
    headers: token,
  });
  return response;
};

// DELETE question
export const deleteQuestion = async questionId => {
  const token = await authorizationHeader();
  const response = await api
    .delete(`/questions/delete/${questionId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// PATCH question
export const patchQuestion = async (questionId, question) => {
  const token = await authorizationHeader();
  const response = await api
    .patch(`/questions/update/${questionId}`, question, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};
