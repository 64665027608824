import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import BoxContainer from '../../style-components/BoxContainer.jsx';
import HeaderContainer from '../../style-components/HeaderContainer.jsx';
import LearnunitQuestionsTable from '../../style-components/Tables/LearnunitQuestionTable.jsx';
import Loading from '../../style-components/Loading.jsx';
import { useGetQuestions, useGetQuestionByEvaluation } from '../../models/questions.js';
import { useGetEvaluation } from '../../models/evaluations.js';

function LearnunitQuestions() {
  const { id, unit_id, eval_id } = useParams();

  const [questions, setQuestions] = useState([]);
  const [questionsData, questionsDataLoading] = useGetQuestions(
    1,
    9999999, // FIXME
    unit_id === '0' ? '' : unit_id
  );
  const [questionsByEvaluationData, questionsByEvaluationDataLoading] =
    useGetQuestionByEvaluation(eval_id);
  const [evaluation] = useGetEvaluation(eval_id);

  const actions = [{}];
  const itemsBreadcrumb = {
    items: [
      { name: 'Cursos', href: '/courses' },
      {
        name: 'Unidades de Aprendizaje',
        href: `/courses/${id}/learning_units`,
      },
      {
        name: 'Evaluaciones',
        href: `/courses/${id}/${unit_id}/evaluations`,
      },
      {
        name: 'Preguntas',
        href: `/courses/${id}/${unit_id}/evaluations/${eval_id}/question`,
      },
      {
        name: `Añadir preguntas a la evaluación ${evaluation?.name}`,
        href: '',
      },
    ],
    maxItems: 5,
  };

  useEffect(() => {
    if (
      questionsData &&
      !questionsDataLoading &&
      questionsByEvaluationData &&
      !questionsByEvaluationDataLoading
    ) {
      const questionsInEvaluationDict = {};
      questionsByEvaluationData.forEach(question => {
        questionsInEvaluationDict[question.id] = true;
      });
      const questionsInUnit = questionsData.questions.filter(
        question => !questionsInEvaluationDict[question.id]
      );
      setQuestions(questionsInUnit);
    }
  }, [
    questionsData,
    questionsDataLoading,
    questionsByEvaluationData,
    questionsByEvaluationDataLoading,
  ]);

  return (
    <BoxContainer>
      <div className='antialiased font-sans w-full'>
        <div className='divide-y divide-primary'>
          <HeaderContainer breadcrumb={itemsBreadcrumb} actions={actions} />
          {questionsDataLoading ? <Loading /> : <LearnunitQuestionsTable questions={questions} />}
        </div>
      </div>
    </BoxContainer>
  );
}

export default LearnunitQuestions;
