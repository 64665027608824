import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiConversation } from 'react-icons/bi';

import Loading from '../Loading.jsx';
import Select from '../Form/Select.jsx';
import HeaderContainer from '../HeaderContainer.jsx';
import Pagination from '../../components/Custom-UI/Tables/Pagination.jsx';
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  TableButton,
  CellName,
} from '../../components/Custom-UI/Tables/Elements.jsx';
// import { SortData } from '../../components/Custom-UI/Tables/Filters.jsx';
import { getDateFromISODate } from '../../utils/date.js';
import { getChats } from '../../models/chat.js';
import { useGetLearnunits } from '../../models/learnunits.js';

const ChatTable = ({ list_chats = null, loadingChats = false } = {}) => {
  const navigate = useNavigate();
  const [chats, setChats] = useState(list_chats || []);
  const [courseLearnunitFilter, setCourseLearnunitFilter] = useState('');
  const [learnunitsData] = useGetLearnunits();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    currentPage: page,
    itemsPerPage: itemsPerPage,
    totalPages: chats?.length,
  });
  const [searchValue, setSearchValue] = useState(''); // eslint-disable-line

  // function handleSearch(option) {
  //   setSearchValue(option.target.value?.toLowerCase());
  //   setPage(1);
  //   setLoading(true);
  // }

  const options = [{ value: '', label: <em>Todas</em> }];
  if (learnunitsData) {
    learnunitsData?.data?.forEach(learnunit => {
      options.push({ value: learnunit.id, label: learnunit.name });
    });
  }

  function handleCourseLearnunit(option) {
    setCourseLearnunitFilter(option.target.value);
    setPage(1);
    setLoading(true);
  }

  useEffect(() => {
    if (!loadingChats && loading) {
      const params = { page: page, limit: itemsPerPage };
      if (courseLearnunitFilter) params.learnunit_id = courseLearnunitFilter;
      if (searchValue) params.search = searchValue;
      const fetchChats = getChats(params);

      fetchChats.then(res => {
        setChats(res.chats);
        setPagination({
          currentPage: res.pagination.currentPage,
          itemsPerPage: itemsPerPage,
          totalPages: res.pagination.totalPages,
        });
        setLoading(false);
      });
    }
  }, [page, pagination, loadingChats, loading, courseLearnunitFilter, searchValue]);

  return (
    <section className='antialiased font-sans w-full'>
      <div className='divide-y divide-primary'>
        <HeaderContainer
          title='Chats'
          // search={{ handleSearch: handleSearch }}
        />
        <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
          <div className='mb-4 flex sm:flex-row flex-col'>
            <Select
              name='learnunit'
              label='Unidad de Aprendizaje'
              value={courseLearnunitFilter}
              onChange={handleCourseLearnunit}
              options={options}
              sx={{ minWidth: '210px' }}
            />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader className='w-72 min-w-72'>Usuario</TableHeader>
                <TableHeader className='w-[24rem]'>Unidad de aprendizaje</TableHeader>
                <TableHeader>N° Consultas</TableHeader>
                <TableHeader>Fecha de creación</TableHeader>
                <TableHeader></TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan='5'>
                    <div className='flex justify-center items-center h-full'>
                      <Loading />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                chats.map(chat => (
                  <TableRow key={chat.id}>
                    <TableCell>
                      <CellName
                        firstLabel={'Nombre'}
                        firstField={chat.user.firstnames}
                        secondLabel={'Apellido'}
                        secondField={chat.user.lastnames}
                      />
                    </TableCell>
                    <TableCell type={'name'}>{chat.name}</TableCell>
                    <TableCell type={'name'}>{chat.messageCount}</TableCell>
                    <TableCell type={'date'}>
                      {getDateFromISODate(chat.details.createdAt)}
                    </TableCell>
                    <TableCell>
                      <TableButton variant={'text'} onClick={() => navigate(`/chats/${chat.id}`)}>
                        <div className='inline-flex items-center my-2'>
                          <div className='mr-1 mt-[0.1rem]'>
                            <BiConversation />
                          </div>
                          Mensajes
                        </div>
                      </TableButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <Pagination
            pagination={pagination}
            setPage={setPage}
            setLoading={setLoading}
            setItemPerPage={setItemPerPage}
          />
        </div>
      </div>
    </section>
  );
};

export default ChatTable;
