import { useAsync } from 'react-use';
import * as Sentry from '@sentry/react';

import api from '../api/axios';
import authorizationHeader from '../api/token';

export function useGetChats({ page = null, limit = null, learnunit_id = null, chat_id = null, user_id = null, search = null } = {}) {
  const { value, loading, error } = useAsync(
    () => getChats({ page, limit, learnunit_id, chat_id, user_id, search }), 
    [page, limit, learnunit_id, chat_id, user_id, search]
  );
  return [value, loading, error];
}

export const getChats = async ({ page = null, limit = null, learnunit_id = null, chat_id = null, user_id = null, search = null } = {}) => {
  const token = await authorizationHeader();
  const baseUrl = '/MediBot/chats';
  const params = new URLSearchParams();

  if (page !== null) params.append('page', page);
  if (limit !== null) params.append('limit', limit);

  if (learnunit_id !== null) params.append('learnunit', learnunit_id);
  if (chat_id !== null) params.append('chat', chat_id);
  if (user_id !== null) params.append('user', user_id);

  if (search !== null) params.append('search', search);

  const queryString = params.toString();
  const fullUrl = queryString ? `${baseUrl}?${queryString}` : baseUrl;

  const chats = await api
    .get(fullUrl, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });

  return chats.data;
};
