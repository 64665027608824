import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import { getChats } from '../../../models/chat';
import Loading from '../../../style-components/Loading.jsx';

export function ChatSidebar({ userId, isCollapsed }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [chats, setChats] = useState([]);

  const handleClick = chat_id => {
    navigate(`/chats/${chat_id}`);
  };

  useEffect(() => {
    if (loading) {
      getChats({ user_id: userId }).then(res => {
        setChats(res.chats);
        setLoading(false);
      });
    }
  }, [loading, userId]);

  return (
    <div
      data-collapsed={isCollapsed}
      className='relative group flex flex-col h-full max-w-full gap-4 p-2 data-[collapsed=true]:p-2 '>
      {!isCollapsed && (
        <div className='flex justify-between p-2 items-center'>
          <div className='flex gap-2 items-center text-2xl'>
            <p className='font-medium'>Chats del usuario</p>
            <span className='text-zinc-300'>({chats.length})</span>
          </div>
        </div>
      )}
      {loading ? (
        <Loading />
      ) : (
        <nav className='grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2 max-w-full overflow-y-scroll overflow-x-hidden'>
          {chats?.map(chat =>
            isCollapsed ? (
              <Tooltip title={chat.name} placement='right' TransitionComponent={Zoom} arrow>
                <button
                  key={chat.id * 10}
                  onClick={() => handleClick(chat.id)}
                  className={`inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 text-gray-500 shadow-sm hover:bg-gray-200 h-11 w-11 md:h-16 md:w-16 min-w-full max-w-full ${
                    chat.id.toString() === id ? 'bg-gray-300' : 'bg-gray-100'
                  }`}>
                  <Avatar alt={`${chat.name}`} sx={{ bgcolor: '#004591' }}>
                    <span className='sr-only'>{chat.name}</span>
                    {chat.name[0]}
                  </Avatar>
                </button>
              </Tooltip>
            ) : (
              <button
                key={chat.id}
                onClick={() => handleClick(chat.id)}
                className={`inline-flex items-center whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50  text-gray-500 shadow-sm hover:bg-gray-200 h-16 rounded-md px-5 shrink justify-start gap-4 min-w-full max-w-full ${
                  chat.id.toString() === id ? 'bg-gray-300' : 'bg-gray-100'
                }`}>
                <div className='flex flex-col min-w-full text-left'>
                  <span className='text-gray-700 text-md truncate'>{chat.name}</span>
                  {chat.details.messages.length > 0 && (
                    <span className='text-zinc-400 text-xs truncate'>
                      ~Medibot: {chat.details.messages[chat.details.messages.length - 1].content}
                    </span>
                  )}
                </div>
              </button>
            )
          )}
        </nav>
      )}
    </div>
  );
}
