import React from 'react';
import { useParams } from 'react-router-dom';

import BoxContainer from '../../style-components/BoxContainer.jsx';
import HeaderContainer from '../../style-components/HeaderContainer.jsx';
import Loading from '../../style-components/Loading.jsx';
import { useGetChats } from '../../models/chat.js';
import { ChatLayout } from './Chat/ChatLayout.jsx';

const ChatMessages = () => {
  const { id } = useParams();
  const [chats, loadingChats] = useGetChats({ chat_id: id });
  const itemsBreadcrumb = {
    items: [
      { name: 'Chats', href: '/chats' },
      { name: 'Mensajes', href: `/chats/${id}` },
    ],
    maxItems: 2,
  };

  return (
    <BoxContainer>
      {loadingChats ? (
        <Loading />
      ) : (
        <div className='divide-y divide-primary max-h-screen h-[90vh]'>
          <HeaderContainer breadcrumb={itemsBreadcrumb} />
          <div className='flex p-10 md:p-8 sm:p-4 items-center justify-center h-5/6'>
            <div className='z-10 border rounded-lg max-w-6xl w-full h-full text-sm lg:flex'>
              <ChatLayout navCollapsedSize={8} chat={chats.chats[0]} />
            </div>
          </div>
        </div>
      )}
    </BoxContainer>
  );
};

export default ChatMessages;
