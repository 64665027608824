import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import AddAlertIcon from '@mui/icons-material/AddAlertOutlined';

import Loading from '../Loading.jsx';
import HeaderContainer from '../HeaderContainer.jsx';
import Modal from '../Modal.jsx';
import NewCourse from '../../components/Courses/NewCourse.jsx';
import EditCourse from '../../components/Courses/EditCourse.jsx';
import Pagination from '../../components/Custom-UI/Tables/Pagination.jsx';
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  TableButton,
  CellName,
} from '../../components/Custom-UI/Tables/Elements.jsx';
import Select from '../Form/Select.jsx';
import { useGetCourses, deleteCourse } from '../../models/course.js';
import { useGetUniversities } from '../../models/universities.js';
import { getCurrentUser } from '../../models/user.js';
import NotificationForm from '../../components/Notification/NotificationForm.jsx';

const year = new Date().getFullYear();
const yearOptions = [{ value: '', label: <em>Todos</em> }].concat(
  Array.from({ length: year - 2021 }, (_, i) => ({
    value: year + 1 - i,
    label: year + 1 - i,
  }))
);

const CoursesTable = () => {
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false); // eslint-disable-line
  const dashboard = false;
  const [yearFilter, setYearFilter] = useState('');
  const [semesterFilter, setSemesterFilter] = useState('');
  const [universityFilter, setUniversityFilter] = useState('');
  const [data, dataLoading] = useGetCourses(
    page,
    itemsPerPage,
    yearFilter,
    semesterFilter,
    universityFilter
  );
  const [universitiesData, loadingUniversities] = useGetUniversities();
  const [pagination, setPagination] = useState({
    currentPage: page,
    itemsPerPage: itemsPerPage,
    totalPages: courses.length,
  });
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [openModalNotification, setOpenModalNotification] = useState(false);
  const [course, setCourse] = useState({});
  const [universities, setUniversities] = useState({});

  useEffect(() => {
    getCurrentUser()
      .then(user => {
        if (user.role === 'ADMIN') {
          setShow(true);
        }
      })
      .catch();
  }, []);

  useEffect(() => {
    if (data && !dataLoading) {
      setCourses(data.courses);
      setPagination({
        currentPage: data.pagination.currentPage,
        itemsPerPage: itemsPerPage,
        totalPages: data.pagination.totalPages,
      });
      setLoading(false);
    }
  }, [data, dataLoading]);

  useEffect(() => {
    if (universitiesData && !loadingUniversities) {
      const universitiesDict = {};
      universitiesData.forEach(university => {
        universitiesDict[university.id] = university.name;
      });
      setUniversities(universitiesDict);
    }
  }, [universitiesData, loadingUniversities]);

  function handleYear(option) {
    if (option.target.value === '') {
      setYearFilter('');
    } else {
      setYearFilter(Number(option.target.value));
    }
    setPage(1);
    setLoading(true);
  }

  function handleSemester(option) {
    setSemesterFilter(option.target.value);
    setPage(1);
    setLoading(true);
  }

  function handleUniversity(option) {
    if (option.target.value === '') {
      setUniversityFilter('');
    } else {
      setUniversityFilter(parseInt(option.target.value));
    }
    setPage(1);
    setLoading(true);
  }

  const handleDelete = async CourseId => {
    const confirmation = window.confirm('¿Estás seguro que quieres eliminar este curso?');
    if (confirmation) {
      const response = await deleteCourse(CourseId);
      if (response.status === 200 || response.status === 201) {
        alert('Curso eliminado con éxito.');
        window.location.reload();
      } else {
        alert('Error al eliminar el curso.');
      }
    }
  };

  return (
    <div className='antialiased font-sans'>
      <Modal open={open} setOpen={setOpen}>
        {modalContent}
      </Modal>
      <Modal open={openModalNotification} setOpen={setOpenModalNotification}>
        <NotificationForm setOpen={setOpenModalNotification} course_id={course.id} />
      </Modal>
      <div className='w-full divide-y divide-primary'>
        <HeaderContainer
          title='Cursos'
          actions={
            dashboard
              ? false
              : [
                  {
                    title: 'Añadir Curso',
                    icon: <AddIcon />,
                    handleClick: () => {
                      setModalContent(<NewCourse setOpen={setOpen} />);
                      setOpen(true);
                    },
                  },
                ]
          }
        />
        <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
          <div className='flex gap-4 mb-4'>
            <Select
              name='year'
              label='Año'
              value={yearFilter}
              onChange={handleYear}
              options={yearOptions}
              sx={{ minWidth: '80px' }}
            />
            <Select
              name='semester'
              label='Semestre'
              value={semesterFilter}
              onChange={handleSemester}
              displayEmpty
              options={[
                { value: '', label: <em>Todos</em> },
                { value: 'Primero', label: 'Primero' },
                { value: 'Segundo', label: 'Segundo' },
              ]}
              sx={{ minWidth: '120px' }}
            />
            <Select
              name='university'
              label='Universidad'
              value={universityFilter}
              onChange={handleUniversity}
              options={[{ value: '', label: <em>Todas</em> }].concat(
                Object.keys(universities)?.map(university_id => ({
                  value: university_id,
                  label: universities[university_id],
                }))
              )}
              sx={{ minWidth: '140px' }}
            />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader className='min-w-72 w-72'>Curso</TableHeader>
                <TableHeader className='min-w-52 w-52'>Universidad</TableHeader>
                {dashboard ? null : (
                  <>
                    <TableHeader>Unidades de Aprendizaje</TableHeader>
                    <TableHeader>Estudiantes</TableHeader>
                    <TableHeader>Notificar</TableHeader>
                  </>
                )}
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {dataLoading || loading ? (
                <TableRow>
                  <TableCell colSpan={dashboard ? '4' : '8'}>
                    <div className='flex justify-center items-center h-full'>
                      <Loading />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                courses.map(course => (
                  <TableRow key={course.id}>
                    <TableCell type='name'>
                      <CellName
                        name={course.name}
                        firstLabel='Año'
                        firstField={course.year}
                        secondLabel='Semestre'
                        secondField={course.semester}
                      />
                    </TableCell>
                    <TableCell>{universities[course.university_id]}</TableCell>
                    {dashboard ? (
                      <TableCell>
                        <TableButton variant={'text'} onClick={() => {}}>
                          Estadísticas
                        </TableButton>
                      </TableCell>
                    ) : (
                      <>
                        <TableCell>
                          <TableButton
                            variant={'text'}
                            onClick={() => navigate(`/courses/${course.id}/learning_units`)}>
                            <div className='inline-flex items-center my-2'>
                              <div className='mr-1'>
                                <CollectionsBookmarkOutlinedIcon />
                              </div>
                              Unidades de Aprendizaje
                            </div>
                          </TableButton>
                        </TableCell>

                        <TableCell>
                          {course.for_all ? null : (
                            <TableButton
                              variant={'text'}
                              onClick={() => navigate(`/courses/${course.id}/my_students`)}>
                              <div className='inline-flex items-center my-2'>
                                <div className='mr-1'>
                                  <GroupsOutlinedIcon />
                                </div>
                                Estudiantes
                              </div>
                            </TableButton>
                          )}
                        </TableCell>
                        <TableCell>
                          <TableButton
                            variant={'text'}
                            onClick={() => {
                              setCourse(course);
                              setOpenModalNotification(true);
                            }}>
                            <div className='inline-flex items-center my-2'>
                              <div className='mr-1'>
                                <AddAlertIcon />
                              </div>
                              Crear notificación
                            </div>
                          </TableButton>
                        </TableCell>
                        <TableCell>
                          <div className='flex w-full justify-between gap-2 items-center'>
                            <TableButton
                              variant={'edit'}
                              onClick={() => {
                                setModalContent(<EditCourse id={course.id} setOpen={setOpen} />);
                                setOpen(true);
                              }}>
                              <EditOutlinedIcon />
                            </TableButton>
                            <TableButton variant={'delete'} onClick={() => handleDelete(course.id)}>
                              <DeleteOutlinedIcon color='red' />
                            </TableButton>
                          </div>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <Pagination
            pagination={pagination}
            setPage={setPage}
            setLoading={setLoading}
            setItemPerPage={setItemsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default CoursesTable;
