import { useAsync } from 'react-use';
import * as Sentry from '@sentry/react';

import api from '../api/axios';
import authorizationHeader from '../api/token';

// POST course
export const postCourse = async course => {
  const token = await authorizationHeader();
  const response = await api
    .post(`/courses/new`, course, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

export function useGetCourses(page, limit, yearFilter, semesterFilter, universityFilter) {
  const { value, loading, error } = useAsync(
    () => getCourses(page, limit, yearFilter, semesterFilter, universityFilter),
    [page, limit, yearFilter, semesterFilter, universityFilter]
  );
  return [value, loading, error];
}

export const getCourses = async (
  page,
  limit,
  yearFilter = '',
  semesterFilter = '',
  universityFilter = ''
) => {
  const token = await authorizationHeader();
  const courses = await api
    .get(
      `/courses?page=${page}&limit=${limit}&yearFilter=${yearFilter}&semesterFilter=${semesterFilter}&universityFilter=${universityFilter}`,
      {
        headers: token,
      }
    )
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return courses.data;
};

// GET all courses with no pagination
export function useGetAllCourses() {
  const { value, loading, error } = useAsync(() => getAllCourses());
  return [value, loading, error];
}

export const getAllCourses = async () => {
  const token = await authorizationHeader();
  const courses = await api
    .get(`/courses/no-paginated`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return courses.data;
};

export const useGetCourseUA = courseId => {
  const { value, loading, error } = useAsync(() => getCoursesUA(courseId), [courseId]);
  return [value, loading, error];
};

export const getCoursesUA = async courseId => {
  const token = await authorizationHeader();
  const data = await api
    .get(`/course-learnunits/${courseId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return data.data;
};

// GET course by id
export function useGetCourse(courseId) {
  const { value, loading, error } = useAsync(() => getCourse(courseId), [courseId]);
  return [value, loading, error];
}

export const getCourse = async courseId => {
  if (!courseId) {
    return {};
  }
  const token = await authorizationHeader();
  const courses = await api
    .get(`/courses/${courseId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return courses.data;
};

//DELETE course
export const deleteCourse = async courseId => {
  const token = await authorizationHeader();
  const courses = await api
    .delete(`/courses/delete/${courseId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return courses;
};

//PATCH course
export const patchCourse = async (courseId, course) => {
  const token = await authorizationHeader();
  const courses = await api
    .patch(`/courses/update/${courseId}`, course, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return courses;
};

// GET courses of teacher
export function useGetTeacherCourses(teacherId) {
  const { value, loading, error } = useAsync(() => getTeacherCourses(teacherId), [teacherId]);
  return [value, loading, error];
}

export const getTeacherCourses = async teacherId => {
  const token = await authorizationHeader();
  const courses = await api
    .get(`/courses/teacher/${teacherId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return courses.data;
};

export const postCourseLearnunit = async learnunitData => {
  const token = await authorizationHeader();
  const response = await api
    .post(`/courses/${learnunitData.course_id}/add_learnunit`, learnunitData, {
      // .post(`/course-learnunits/add_learnunit_to_course`, learnunitData, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};
